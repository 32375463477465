body.is-far-sighted {
  font-size: 16px;
  h1 {
    font-size: 34px;
    word-break: break-all;
  }
  h2 {
    font-size: 34px;
    word-break: break-all;
  }
  h3 {
    font-size: 18px;
    word-break: break-all;
  }
  h5 {
    font-size: 16px;
  }

  ng-select {
    font-size: 1.3em;
  }

  .mat-mdc-header-cell {
    font-size: 16px;
  }

  .mat-mdc-cell {
    font-size: 16px;
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .mat-mdc-button,
  .mat-mdc-raised-button,
  .mat-mdc-icon-button,
  .mat-mdc-outlined-button,
  .mat-mdc-unelevated-button,
  .mat-mdc-fab,
  .mat-mdc-mini-fab {
    font-size: 17px;
  }

  .mat-icon {
    font-size: 28px;
    width: 28px;
    height: 28px;
  }

  .mat-mdc-mini-fab {
    width: 42px;
    height: 42px;
  }

  app-breadcrumbs {
    font-size: 20px;
  }

  app-info-pane {
    .wrapper {
      &.wrapper--small p {
        font-size: 1.25em;
      }
      &.wrapper--med p {
        font-size: 1.35em;
      }
      &.wrapper--large p {
        font-size: 1.5em;
      }
    }
  }
}
