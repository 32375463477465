.info-line {
  display: flex;
  margin-bottom: 4px;
  padding-bottom: 4px;
  gap: 6px;
  justify-content: space-between;
  border-bottom: 1px dashed gray;
  align-items: center;
  line-height: 1.05;
  min-height: 20px;

  .label {
    font-style: italic;
  }

  .value {
    margin-left: auto;
    text-align: right;
  }
}
