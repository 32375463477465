@use '@angular/material' as mat;
@import "mixins";
@import "vars";

html,
body {
  height: 100%;
  margin: 0;
  overscroll-behavior-y: contain;
}

html {
  // your css to create a cover image
  padding: env(safe-area-inset); // <- this is the missing piece. Add it.
}

body {
  position: relative;
  background: $primary;
  user-select: none;
}

* {
  letter-spacing: 0 !important;
}

app-root {
  background: $white;
  display: block;
  height: 100%;
  overscroll-behavior: contain;
}

a {
  cursor: pointer;
  text-decoration: underline;

  &:hover,
  &:focus {
    text-decoration: none;
  }

  &:visited {
    color: inherit;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


textarea {
  min-height: 60px;
}

// Default bottom spacing for form fields
mat-form-field,
ng-select.ng-select,
mat-slide-toggle {
  &:not(.no-space-bottom) {
    margin-bottom: 12px;
  }
}

// MAT Extra Classes
.custom-mat-backdrop {
  background-color: rgba(black, .2);
}
