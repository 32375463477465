@use '@angular/material' as mat;

// Screen sizes
$mq-media-type: screen;
$mq-breakpoints: (
  mp: 320px,
  ml: 480px,
  tp: 680px,
  tl: 800px,
  d: 992px,
  dl: 1200px,
  dxl: 1600px
);

$space: 16px;

$customFormFieldsFontSize: 14px;
$customFormFieldsMinHeight: 44px;

$appDialogTpAndUpTopMargin: 5vh;

$matInputFieldDisabledColor: rgba(0, 0, 0, .6);

$font-folder: '/assets/fonts/';


$mat-custom-theme: (
  50 : #e0ebf7,
  100 : #b3ceea,
  200 : #80aedc,
  300 : #4d8dce,
  400 : #2674c4,
  500 : #005cb9,
  600 : #0054b2,
  700 : #004aaa,
  800 : #0041a2,
  900 : #003093,
  A100 : #beceff,
  A200 : #8ba8ff,
  A400 : #5881ff,
  A700 : #3f6eff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #ffffff,
  )
);

// Copy the palettes from your selected theme (usually theme.scss).
$app-primary: mat.define-palette($mat-custom-theme, 900);
$app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$app-warn: mat.define-palette(mat.$red-palette);

// Create your Sass color vars (will be available in all the project)
$primary: mat.get-color-from-palette($app-primary);
$accent: mat.get-color-from-palette($app-accent);
$warn: mat.get-color-from-palette($app-warn);
$positive: #4caf50;
$notification: #ff9800;
$purple: mat.get-color-from-palette(mat.define-palette(mat.$purple-palette, 700));
$calm: #29b6f6;
$calm-light: lighten($calm, 35);
$brown: mat.get-color-from-palette(mat.define-palette(mat.$brown-palette, 700));

$white: white;
$gray-lighter: mat.get-color-from-palette(mat.define-palette(mat.$gray-palette, 100));
$gray-light: mat.get-color-from-palette(mat.define-palette(mat.$gray-palette, 300));
$gray: mat.get-color-from-palette(mat.define-palette(mat.$gray-palette));
$gray-dark: mat.get-color-from-palette(mat.define-palette(mat.$gray-palette, 600));
$gray-darker: mat.get-color-from-palette(mat.define-palette(mat.$gray-palette, 600));
$dark-primary-text: rgba(black, 0.87); // core/theming/_palette.scss
$dark-secondary-text: rgba(black, 0.54); // core/theming/_palette.scss

$module-color-default: $gray;
$module-color-inbounds: mat.get-color-from-palette(mat.define-palette(mat.$light-blue-palette, 700));
$module-color-pickOrders: $primary;
$module-color-outbounds: $positive;
$module-color-stockChecks: $notification;
$module-color-manageProducts: mat.get-color-from-palette(mat.define-palette(mat.$purple-palette, 700));
$module-color-packaging: mat.get-color-from-palette(mat.define-palette(mat.$lime-palette, 700));

$border: 2px solid $gray-light;



