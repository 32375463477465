@import "vars";

// no background
.mat-mdc-form-field-focus-overlay,
.mdc-text-field--filled:not(.mdc-text-field--disabled),
.mdc-text-field--disabled.mdc-text-field--filled {
  background: none;
}

// disabled color
.mdc-text-field--disabled .mdc-text-field__input,
.mat-mdc-select-disabled .mat-mdc-select-value,
.mat-mdc-checkbox-disabled label {
  color: $matInputFieldDisabledColor;
}

// Generic Form Fields
mat-form-field.mat-mdc-form-field {
  .mdc-floating-label,
  .mat-mdc-form-field-input-control {
    font-size: $customFormFieldsFontSize;
  }

  // required label marker
  &.mat-focused {
    .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)::after {
      color: $accent;
    }
  }
}

// Less height for field
div.mdc-text-field:not(.mdc-text-field--outlined) {
  padding-left: 0;
  padding-right: 0;

  .mat-mdc-form-field-infix {
    padding-top: 14px !important;
    padding-bottom: 4px !important;
    min-height: $customFormFieldsMinHeight;
  }
}

.mat-form-field-appearance-outline {
  margin-top: 6px;
}

//div.mdc-text-field.mdc-text-field--outlined {
//  padding-left: 0;
//  padding-right: 0;
//  margin-top: 6px;
//
//  .mat-mdc-form-field-infix {
//    padding-top: 11px !important;
//    padding-bottom: 10px !important;
//    min-height: $customFormFieldsMinHeight;
//  }
//}

// Label
.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 25px;
}

.mdc-text-field--filled label.mdc-floating-label--float-above {
  transform: translateY(-146%) scale(0.75);
}

// Mat Select
mat-select .mat-mdc-select-value-text {
  font-size: $customFormFieldsFontSize;
}

mat-option.mat-mdc-option {
  min-height: 40px;

  .mdc-list-item__primary-text {
    font-size: $customFormFieldsFontSize;
  }
}

div.mdc-menu-surface {
  &.mat-mdc-select-panel,
  &.mat-mdc-autocomplete-panel {
    padding-top: 0;
    padding-bottom: 0;
  }
}

// Invalid warning
form:not(.no-invalid-warnings) {
  textarea.ng-invalid[required],
  .mat-datepicker-input.ng-invalid[required],
  .mat-mdc-input-element.ng-invalid[required],
  mat-select.ng-invalid[required] {
    background-color: rgba($notification, .4);
    opacity: 1;
  }
}

// done for paginator list-size options to be fully plotted, when selected
.mat-mdc-option .mat-pseudo-checkbox-minimal {
  margin-left: 0 !important;
}
