@import "mixins";
@import "vars";

.app-dialog {
  width: clamp(500px, calc(100vw - 40px), 650px);
  max-height: calc((100vh - $appDialogTpAndUpTopMargin) - $space);

  .mat-mdc-dialog-content {
    max-height: calc((100vh - $appDialogTpAndUpTopMargin) - ($space + 60px));
  }

  // Less vertical padding
  .mdc-dialog mat-dialog-content.mdc-dialog__content {
    padding: 0 $space $space $space;
    overscroll-behavior: contain;
    color: $dark-primary-text;
  }

  // Actions -> fill out space
  mat-dialog-actions.mat-mdc-dialog-actions {
    padding: 0 14px 12px;

    .mdc-icon-button {
      flex: 0 0;
    }

    .mdc-button {
      flex: 1 1;
    }
  }
}

// Header
.app-dialog-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 4px;
  gap: 8px;
  min-height: 32px;
  position: sticky;
  top: 0;
  background: rgba(white, .92);
  z-index: 10;
  margin-left: -$space;
  margin-right: -$space;
  padding-left: $space;
  padding-top: 6px;

  .title {
    margin: 0 auto 0 0;
    line-height: 1;
    flex-shrink: 1;
    word-break: break-all;

    a {
      color: $primary;
    }
  }

  .mdc-icon-button.mat-warn {
    margin-left: auto;
  }

  @include mq($until: ml) {
    .title {
      font-size: 18px;
      margin-right: 2px;
    }
  }
}
