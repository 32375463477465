.category-detail {
  .info-pane-wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
  }

  .header-wrapper {
    padding: 0;
    position: sticky;
    top: 0;
    background: white;
    z-index: 1;
  }
}

.category-detail-header {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 100%;

  > button {
    line-height: 1;
  }

  .info {
    padding-top: $space  * .23;
    padding-bottom: $space  * .23;

    .count {
      width: 14px;
      height: 14px;
      line-height: 14px;
      display: inline-flex;
      transform: translateY(-2px);
      overflow: hidden;
      background: $accent;
      color: white;
      border-radius: 50%;
      font-size: 10px;
      font-weight: bold;
      align-items: center;
      justify-content: center;
    }

    > * {
      line-height: 1;

      &:last-child {
        margin-bottom: 0;
      }

      &:not(:last-child) {
        margin-bottom: $space  * .25;
      }
    }
  }

  .buttons {
    margin-left: auto;
    display: flex;
    align-items: center;

    .mat-mdc-icon-button {
      padding: 4px;
      width: 40px;
      height: 40px;
    }
  }

  .icon-info {
    display: flex;
    align-items: center;
    flex-shrink: 1;
    overflow-x: hidden;

    app-list-type-icon {
      font-size: 24px;
      width: 24px;
      height: 24px;
      opacity: .7;
      flex-grow: 0;
      flex-shrink: 0;
    }

    .info {
      flex-grow: 1;
      flex-shrink: 1;
      margin-left: $space * .5;
      overflow-x: hidden;
      padding-right: 10px;

      .info__top {
        display: block;
      }

      .info__text {
        display: block;
        max-width: 100%;
        white-space: nowrap;
        overflow-x: hidden;
        overflow-y: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  &.is-far-sighted {
    flex-wrap: wrap;
  }
}

mat-button-toggle-group.mat-button-toggle-group {
  border-radius: 0;
  border-bottom: none;
  border-top: none;
  align-self: stretch;
  flex-shrink: 0;
  flex-grow: 0;
  margin-left: auto;

  .mat-button-toggle-checked {
    background: white;
  }

  .pause {
    color: $notification;
  }

  .transport, .photos {
    color: $brown;
  }

  .check, .print {
    color: $positive;
  }
}
