@use "sass:math";

.header-wrapper + .category-list-filter-forms {
  border-top: $border;
  border-top-style: dashed;
}

.category-list-filter-forms {
  font-size: 16px;
  padding-top: $space * .5;
  padding-bottom: $space * .5;

  .mat-slide-toggle {
    height: 32px;
  }

  .category-list-filter-forms__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .no-categories-to-show {
    display: block;
    margin-top: $space;
  }
}

.category-list .category {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: $space;
  border-top: $border;
  line-height: 1;
  text-decoration: none;

  &:last-child {
    border-bottom: $border;
  }

  &--incomplete {
    padding-top: 4px;
    padding-bottom: 4px;
    color: $dark-primary-text;

    .name {
      color: $dark-primary-text;
    }

    .count {
      color: $dark-secondary-text;
    }
  }

  &--completed {
    padding-top: math.div($space, 3.5);
    padding-bottom: math.div($space, 3.5);
    color: $positive;
  }

  app-list-type-icon {
    opacity: .6;
    font-size: 20px;
    height: 20px;
  }

  .info {
    flex-basis: 100%;
    padding-left: $space * .5;

    .name {
      display: block;
      margin-bottom: math.div($space, 6);
      line-height: 1;
      font-size: 18px;
    }
  }

  .check-icon {
    $icon-size: 32px;
    font-size: $icon-size;
    height: $icon-size;
    margin-right: 8px;
  }

  app-weight {
    margin-left: 4px;
  }
}

.category-list.is-far-sighted .category {
  .info .name {
    font-size: 20px;
  }

  .count {
    font-size: 18px;
  }
}
