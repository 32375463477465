@import "vars";

mat-accordion {
  .mat-expansion-panel-header {
    padding-left: $space;
    padding-right: $space;
  }

  .mat-expansion-panel-body {
   padding: 0 $space;
  }
}
