.back-btn.mat-mdc-icon-button {
  flex-shrink: 0;
  flex-grow: 0;
  height: 48px;
  width: 48px;

  mat-icon {
    transform: translateX(4px);
  }
}
