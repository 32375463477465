@import '../vendor/mq';
@import "vars";

// Hide elements
@mixin visually-hidden() {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
}
.visually-hidden {
    @include visually-hidden();
}
