mat-accordion.app-accordion {
  display: block;

  .mat-content {
    align-items: center;
  }

  mat-expansion-panel-header .mat-content mat-icon {
    color: $gray;
    margin-right: 6px;
  }

  mat-expansion-panel.mat-expanded mat-expansion-panel-header .mat-content mat-icon {
    color: $calm;
  }

  mat-expansion-panel.map .mat-expansion-panel-body {
    padding-left: 0;
    padding-right: 0;
  }

  .mat-expansion-panel-spacing {
    margin: 6px 0;
  }

  @include mq($until: tp) {
    .table-wrapper {
      display: block;
      margin-right: -$space;
      margin-left: -$space;
    }
  }
}

