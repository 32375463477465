.table-product-item-info {
  display: flex;
  align-items: center;

  .img {
    margin-right: 8px;
    background-position: center center;
    background-size: contain;
    width: 40px;
    height: 40px;
    flex-grow: 1;
    flex-shrink: 0;
    background-repeat: no-repeat;
  }

  .text {
    padding: 4px 0;

    > * {
      line-height: 1;
      margin-bottom: 0;
      display: block;
    }
  }

  h3 {
    font-size: 15px;
    font-weight: bold;
  }

  h4 {
    font-size: 16px;
    margin-top: 2px;
    color: $dark-primary-text;
  }
}
