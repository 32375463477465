@import "vars";
@import "mixins";

.full-screen-dialog {

  @include mq($until: tp) {
    max-height: none;
    max-width: none;
    width: 100%;
    height: 100%;

    .mat-mdc-dialog-content {
      max-height: 100vh;
    }
  }

  @include mq($from: tp) {
    margin-top: 10px;

    .mat-mdc-dialog-content {
      max-height: calc(100vh - 150px);
    }
  }
}

.full-screen-dialog {
  @include mq($from: tp) {
    width: clamp(500px, calc(100vw - 40px), 880px);
  }
}
