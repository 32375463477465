// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@use "sass:map";
@use '@angular/material' as mat;

/* Styles to be applied to buttons */
$my-custom-button: mat.define-typography-level(
  $font-family: 'Roboto',
  $font-weight: 500,
  $font-size: 1em,
  $line-height: 1,
  $letter-spacing: 'normal'
);

/* Styles to be applied to input-fields */
$my-custom-input: mat.define-typography-level(
  $font-family: 'Roboto',
  $font-weight: 400,
  $font-size: 1rem,
  $line-height: 1,
  $letter-spacing: 'normal'
);

/* Merge custom configs into existing config */
$my-typography-config: map.merge(
    mat.define-typography-config(
      $button: $my-custom-button
    ),
    (
      /* 'input'-property will have to be assigned under '@mixin typography' further below */
      'input': $my-custom-input
    )
);

/* Apply custom config */
@include mat.all-component-typographies($my-typography-config);

@include mat.core();

$mat-custom-theme: (
  50 : #e0ebf7,
  100 : #b3ceea,
  200 : #80aedc,
  300 : #4d8dce,
  400 : #2674c4,
  500 : #005cb9,
  600 : #0054b2,
  700 : #004aaa,
  800 : #0041a2,
  900 : #003093,
  A100 : #beceff,
  A200 : #8ba8ff,
  A400 : #5881ff,
  A700 : #3f6eff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #ffffff,
  )
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$begra-primary: mat.define-palette($mat-custom-theme);
$begra-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$begra-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$begra-theme: mat.define-light-theme(
    (
      color: (primary: $begra-primary, accent: $begra-accent, warn: $begra-warn),
      typography: $my-typography-config,
    )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($begra-theme);

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


@import "~@ng-select/ng-select/themes/material.theme.css";

@import 'styles/exports/mixins';
@import 'styles/exports/vars';

@import 'styles/fonts/roboto';

@import 'material-icons/iconfont/filled.css';
@import 'material-icons/iconfont/outlined.css';

@import 'styles/vendor/overrides/ng-select';

@import 'styles/vendor/overrides/mat/menu';
@import 'styles/vendor/overrides/mat/form-field';
@import 'styles/vendor/overrides/mat/button';
@import 'styles/vendor/overrides/mat/option';
@import 'styles/vendor/overrides/mat/table';
@import 'styles/vendor/overrides/mat/accordion';

@import 'styles/global';
@import 'styles/helper-classes';

@import 'styles/dialogs/app-dialog';
@import 'styles/dialogs/full-screen-dialog';

@import 'styles/elements/app-button-is-pending.directive';
@import 'styles/elements/category-list';
@import 'styles/elements/category-detail';
@import 'styles/elements/table-product-item-info';
@import 'styles/elements/back-btn';
@import 'styles/elements/app-accordion';
@import 'styles/elements/info-line';

@import 'styles/far-sighted';


