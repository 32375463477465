@use '@angular/material' as mat;
@use "sass:map";
@import "vars";

// Container
.container {
  padding: $space;
  max-width: 100vw;
  box-sizing: border-box;
}

// Row Of Buttons
.buttons-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  flex-wrap: wrap;

  .mdc-icon-button {
    flex: 0 0;
  }

  .mdc-button {
    flex: 1 1;
  }
}

// Display
.display-none {
  display: none !important;
}

// 1 property definitions (sorta)
.full-width {
  width: 100%;
}

// TEXT
.align-right {
  text-align: right;
}

.break-all {
  word-break: break-all;
}

.break-none {
  word-break: keep-all;
}

$baseColors: (
  // MAT
  'primary': $primary,
  'accent': $accent,
  'warn': $warn,
  // Experi
  'calm': $calm,
  'notification': $notification,
  'positive': $positive
);

$colorColors: (
  'white': #fff,
  'gray': $gray,
  'brown': $brown,
  'purple': $purple,
  'gray-dark': $gray-dark,
  'primary-text': $dark-primary-text,
  'secondary-text': $dark-secondary-text,
);

@each $name, $color in map.merge($baseColors, $colorColors) {
  .color--#{$name} {
    color: $color !important;
  }
}

$backgroundColorColors: (
  'calm-light': $calm-light,
);

@each $name, $color in map.merge($baseColors, $backgroundColorColors) {
  .background--#{$name} {
    background-color: $color !important;
  }
}
